import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfig } from '../../../config/api.config';
import {
  ApiKeyInfo,
  Consent,
  ConsentInfo,
  ConsentPayload,
  ConsentUserDataResponse
} from '../../../interfaces/consent.interface';
import { ApiResponse } from '../../../interfaces/response.interface';


@Injectable({
  providedIn: 'root'
})
export class ConsentResource {

  constructor(
    private readonly http: HttpClient) {
  }


  public getAllConsents(): Observable<Consent[]> {
    return this.http.post<Consent[]>(ApiConfig.CONSENT_ALL, {});
  }


  public getConsentInfo(payload: ConsentPayload): Observable<ConsentInfo> {
    return this.http.post<ConsentInfo>(ApiConfig.CONSENT_INFO, payload);
  }


  public deleteConsent(payload: ConsentPayload) {
    return this.http.post(ApiConfig.CONSENT_DELETE, payload);
  }


  public getAllConsentsAndApiKey(): Observable<ApiKeyInfo> {
    return this.http.post<ApiKeyInfo>(ApiConfig.CONSENT_ALL_AND_API_KEY, {});
  }


  public issueLongTermConsent(headers: HttpHeaders): Observable<ApiKeyInfo> {
    return this.http.post<ApiKeyInfo>(ApiConfig.CONSENT_LONG_TERM_AGREEMENT, {}, { headers });
  }


  public revokeLongTermConsent(headers: HttpHeaders): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(ApiConfig.CONSENT_LONG_TERM_AGREEMENT, { headers });
  }


  public getUserAccounts(payload: ConsentPayload): Observable<ConsentUserDataResponse> {
    return this.http.post<ConsentUserDataResponse>(ApiConfig.CONSENT_USER_ACCOUNTS, payload);
  }


  public getExternalRepresentation(payload: ConsentUserDataResponse): Observable<ConsentUserDataResponse> {
    return this.http.post<ConsentUserDataResponse>(ApiConfig.CONSENT_EXTERNAL_REPRESENTATION, payload);
  }


  public authoriseConsentOwnApplication(payload: ConsentUserDataResponse, headers: HttpHeaders): Observable<{
    status: string
  }> {
    return this.http.post<{ status: string }>(ApiConfig.AUTHORIZE_CONSENT_OWN_APPLICATION, payload, { headers });
  }
}
