import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { monthCountNumberMap, monthToNumberMap, UNIQ_DATE_FORMAT } from '../config/date.config';
import { DateEnum } from '../enums/date.enum';
import { SharedEnum } from '../enums/shared.enum';
import { ExpireDateObj } from '../interfaces/date.interface';


export namespace DateFormat {

  export function changeDateEnding(date: string): string {
    let changedDate: string = '';

    if (date) {
      changedDate = DateFormat.formatDateWithSuffix(date);
    }

    return changedDate;
  }


  export function changeDateFormat(date: string, translate: TranslateService): string {
    const parts: string[] = date.split('.');
    const day: string = parts[0];
    const monthName: string = parts[1];
    const monthNumber: number = monthCountNumberMap[monthName];

    const suffix: string = getMonthNameWithSuffix(monthNumber);

    return `${ day } ${ translate.instant(suffix) }`;
  }


  export function replaceDotsWithComma(value: number): string {
    const amount: string = value.toString();

    if (amount && amount.includes('.')) {
      return amount.replace(/\./g, ',');
    }
    return amount;
  }


  export function addedSymbols(value: number): string {
    const amount: string = value.toString();

    if (amount && amount.includes('.')) {
      return amount.replace(/\./g, ',');
    }

    if (amount && !amount.includes('.') && !amount.includes(',')) {
      return amount + ',' + '00';
    }

    return amount;
  }


  export function addedFixedSymbols(value: number): string {
    const amount: string = value.toFixed(2);

    if (amount && amount.includes('.')) {
      return amount.replace(/\./g, ',');
    }

    return amount;
  }


  export function replaceSymbols(value: string): string {
    if (value && value.includes('.')) {
      return value.replace(/\./g, ',');
    }

    return value;
  }


  export function changeSymbols(value: string): string {
    if (value && value.includes(',')) {
      return value.replace(/,/g, '.');
    } else {
      return value;
    }
  }


  export function cutStringFromComma(inputString: string): string {
    const commaIndex = inputString.indexOf(',');
    if (commaIndex !== -1) {
      return inputString.substring(0, commaIndex);
    } else {
      return inputString;
    }
  }


  export function cutStringFromCommaUniverse(inputString: string): string {
    const commaIndex = inputString.indexOf(',');
    if (commaIndex !== -1) {
      const substringAfterComma = inputString.substring(commaIndex + 1);
      return inputString.substring(0, commaIndex) + '.' + substringAfterComma;
    } else {
      return inputString;
    }
  }


  export function replaceCommaWithDot(value: string): number {
    const formattedValue: string = value.replace(/,/g, '.');
    return parseFloat(formattedValue);
  }


  export function transformTimestampDate(timestamp: number): string {
    let resultDate: string = '';

    if (timestamp) {
      const date: Date = new Date(timestamp);
      const dateString: string = new Intl.DateTimeFormat('ru-RU', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      }).format(date);

      resultDate = `${ dateString.slice(0, -2) }`;
    }

    return resultDate;
  }


  export function getDateObjFromTimestamp(timestamp: number): ExpireDateObj {
    const date: Date = new Date(timestamp);
    const month: string = new Intl.DateTimeFormat('ru-RU', {
      month: '2-digit'
    }).format(date);
    const year: string = date.getFullYear().toString();

    return { month, year };
  }


  export function transformTimestampToShort(timestamp: number): string {
    let resultDate: string = '';

    if (timestamp) {
      const date: Date = new Date(timestamp);
      const day: string = new Intl.DateTimeFormat('ru-RU', {
        day: 'numeric'
      }).format(date);
      const month: string = new Intl.DateTimeFormat('ru-RU', {
        month: '2-digit'
      }).format(date);

      resultDate = `${ day }.${ month }`;
    }

    return resultDate;
  }


  export function transformTimestampToExpire(timestamp: number): string {
    let resultDate: string = '';

    if (timestamp) {
      const date: Date = new Date(timestamp);
      const month: string = new Intl.DateTimeFormat('ru-RU', {
        month: '2-digit'
      }).format(date);
      let year: string = new Intl.DateTimeFormat('ru-RU', {
        year: 'numeric'
      }).format(date);

      if (year.length === 4) {
        year = year.slice(2);
      }

      resultDate = `${ year }${ month }`;
    }

    return resultDate;
  }


  export function timestampInMsToDate(timestamp: number): string {
    const date = new Date(timestamp);
    const options = { year: 'numeric', month: 'long', day: '2-digit', hour: '2-digit', minute: '2-digit' };
    const formattedDate = new Intl.DateTimeFormat('ru-RU', options as any).format(date);
    const year = date.getFullYear();

    return formattedDate.replace(year.toString(), '2024').replace(' г. в ', ', ');
  } // преобразует дату к формату "DD MM YYYY, HH:MM"


  export function remapISODate(isoDate: string){
    const date = new Date(isoDate);
    const timestamp = date.getTime();

    // Добавляем разницу с временем UTC, чтобы сохранить точность при обратном преобразовании
    const offset = date.getTimezoneOffset() * 60 * 1000;

    return timestamp - offset;
  } // преобразует дату в timeStamp"


  export function formatDate(timestamp: number): string {
    const date: Date = new Date(timestamp);
    const day: number = date.getDate();
    const month: number = date.getMonth() + 1;
    const year: number = date.getFullYear();

    return `${ day < 10 ? '0' + day : day }.${ month < 10 ? '0' + month : month }.${ year }`;
  }


  export function formatDateWithTime(timestamp: number): string {
    const date: Date = new Date(timestamp);
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${hours}:${minutes} ${formatDate(timestamp)}`;
  }


  export function fullDate(): string {
    const date: Date = new Date();
    const year: number = date.getFullYear();
    const month: number = date.getMonth() + 1;
    const day: number = date.getDate();

    return `${ year }-${ month.toString().padStart(2, '0') }-${ day.toString().padStart(2, '0') }`;
  }


  export function formatDateWithSuffix(inputDate: string): string {
    const parts: string[] = inputDate.split(' ');
    const day: string = parts[0];
    const monthName: string = parts[1];
    const monthNumber: number = monthToNumberMap[monthName];

    const suffix: string = getMonthNameWithSuffix(monthNumber);

    return `${ day } ${ suffix }`;
  }


  export function getMonthFromExpireDate(expireDate: string): string {
    return expireDate.substring(0, 2);
  }


  export function getYearFromExpireDate(expireDate: string): string {
    const lastStrPart: string = expireDate.substring(2).replaceAll('/', '');
    return '20' + lastStrPart;
  }


  export function transformExpireDate(timestamp: number, format: 'slash' | 'dot' | 'short' | 'common' | 'mslash' = 'slash'): string {
    if (!timestamp) {
      return '';
    }

    const date: Date = new Date(timestamp);

    const day: string = addLeadingZero(date.getDate());
    const month: string = addLeadingZero(date.getMonth() + 1);
    const year: number = date.getFullYear();
    const shortYear: number = date.getFullYear() % 100;

    if (format === SharedEnum.TimestampToDateFormat.DOT) {
      return `${ day }.${ month }.${ year }`;
    } else if (format === SharedEnum.TimestampToDateFormat.SHORT) {
      return `${ day }.${ month }`;
    } else if (format === SharedEnum.TimestampToDateFormat.COMMON) {
      return `${ month }${ shortYear }`;
    } else if (format === SharedEnum.TimestampToDateFormat.MSLASH || format === SharedEnum.TimestampToDateFormat.SLASH) {
      return `${ month }/${ shortYear }`;
    } else {
      return `${ day }/${ shortYear }`;
    }
  }


  export function checkExpireDate(expireDate: number): boolean {
    const currentDate: number = Date.now();
    const differenceInMilliseconds: number = expireDate - currentDate;
    const differenceInDays: number = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));

    return differenceInDays <= 30;
  }


  export function getMonthNameWithSuffix(month: number): string {
    const dateMonthEndings = DateEnum.DateMonthEndings;

    let suffix: string;

    if (month === 1) {
      suffix = dateMonthEndings.JANUARY;
    } else if (month === 2) {
      suffix = dateMonthEndings.FEBRUARY;
    } else if (month === 3) {
      suffix = dateMonthEndings.MARCH;
    } else if (month === 4) {
      suffix = dateMonthEndings.APRIL;
    } else if (month === 5) {
      suffix = dateMonthEndings.MAY;
    } else if (month === 6) {
      suffix = dateMonthEndings.JUNE;
    } else if (month === 7) {
      suffix = dateMonthEndings.JULY;
    } else if (month === 8) {
      suffix = dateMonthEndings.AUGUST;
    } else if (month === 9) {
      suffix = dateMonthEndings.SEPTEMBER;
    } else if (month === 10) {
      suffix = dateMonthEndings.OCTOBER;
    } else if (month === 11) {
      suffix = dateMonthEndings.NOVEMBER;
    } else if (month === 12) {
      suffix = dateMonthEndings.DECEMBER;
    }

    return suffix!;
  }


  export function convertDate(inputDate: string, translateService?: TranslateService): string {
    const dateMonthEndings = DateEnum.DateMonthEndings;
    const months = [
      dateMonthEndings.JANUARY,
      dateMonthEndings.FEBRUARY,
      dateMonthEndings.MARCH,
      dateMonthEndings.APRIL,
      dateMonthEndings.MAY,
      dateMonthEndings.JUNE,
      dateMonthEndings.JULY,
      dateMonthEndings.AUGUST,
      dateMonthEndings.SEPTEMBER,
      dateMonthEndings.OCTOBER,
      dateMonthEndings.NOVEMBER,
      dateMonthEndings.DECEMBER
    ];

    const parts: string[] = inputDate.split(/[/ :]/);
    const day: number = parseInt(parts[0], 10);
    const month: DateEnum.DateMonthEndings = months[parseInt(parts[1], 10) - 1];
    const year: number = parseInt(parts[2], 10);
    const time: string = parts[3] && parts[4] ? parts[3] + ':' + parts[4] : parts[3] ? parts[3] + ':00' : '';

    return `${ day } ${ translateService ? translateService.instant(month) : month } ${ year }${ time ? ', ' + time : '' }`;
  }


  export function convertDateDay(inputDate: string): string {
    const dateMonthEndings = DateEnum.DateMonthEndings;
    const dateDays = DateEnum.DateDays;
    const months = [
      dateMonthEndings.JANUARY,
      dateMonthEndings.FEBRUARY,
      dateMonthEndings.MARCH,
      dateMonthEndings.APRIL,
      dateMonthEndings.MAY,
      dateMonthEndings.JUNE,
      dateMonthEndings.JULY,
      dateMonthEndings.AUGUST,
      dateMonthEndings.SEPTEMBER,
      dateMonthEndings.OCTOBER,
      dateMonthEndings.NOVEMBER,
      dateMonthEndings.DECEMBER
    ];

    const days = [
      dateDays.SUN, dateDays.MON, dateDays.TUE, dateDays.WED, dateDays.THU, dateDays.FRI, dateDays.SAT
    ];

    const dateObj = new Date(inputDate);
    const dayOfWeek = days[dateObj.getDay()];
    const month = months[dateObj.getMonth()];

    return `${ dateObj.getDate() } ${ month }, ${ dayOfWeek }`;
  }


  export function convertDateTime(input: string): string {
    const date: Date = new Date(input);

    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return date.toLocaleDateString('ru-RU', options as any).replace('г. в', ',');
  }


  export function convertDateFormat(input: Date): string {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return input.toLocaleDateString('ru-RU', options as any).replace('г. в', ',');
  }


  export function transformTimestampToFullDate(timestamp: number): string {
    const date = new Date(timestamp);
    const options = {
      weekday: 'short',
      month: 'short',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short'
    };
    return date.toLocaleString('en-US', options as any);
  } // TODO: получим "Wed Apr 10 2024 00:00:00 GMT+0300 (Moscow Standard Time)"


  export function millisecondsToMinutesSeconds(ms: number): string {
    const secondsTotal = ms / 1000;
    const seconds = secondsTotal % 60;
    const minutes = parseInt((secondsTotal / 60).toString());
    return minutes.toString().concat(':').concat(seconds === 0 ? '00' : seconds.toString());
  }


  export function minutesSecondsToMilliseconds(time: string): number {
    const split = time.split(':');
    if (Array.isArray(split)) {
      const seconds = parseInt(split[1]);
      const minutes = parseInt(split[0]);
      return 1000 * (seconds + 60 * minutes);
    } else {
      return 0;
    }
  }


  export function transformStringToDate(value: string): Date {
    return moment.utc(value, UNIQ_DATE_FORMAT).toDate();
  }


  export function transformRange(value: Date | null): string {
    if (value instanceof Date) {
      const day = value.getDate();
      const month = value.getMonth() + 1;
      const year = value.getFullYear();
      return `${ day < 10 ? '0' : '' }${ day }.${ month < 10 ? '0' : '' }${ month }.${ year }`;
    } else {
      return '';
    }
  }


  export function transformDateToTimestamp(inputData: string): number {
    const parts: string[] = inputData.split('.');
    const formattedDateStr: string = `${ parts[2] }-${ parts[1] }-${ parts[0] }`;

    return new Date(formattedDateStr).getTime();
  }


  export function transformFullDateToTimestamp(inputData: string | any): number {
    const date = moment.parseZone(inputData).startOf('day');
    return date.valueOf();
  }


  export function transformDateToTimestampRevert(inputData: string | any): number {
    const date = moment.parseZone(inputData).endOf('day');
    return date.valueOf();
  }


  export function translateDate(inputData: string, translate: TranslateService): string {
    const parts = inputData.split(' ');
    const monthToTranslate = parts[1].replace(',', '');
    const translatedMonth = translate.instant(monthToTranslate);

    parts[2] = parts[2].replace(',', '');

    return `${ parts[0] } ${ translatedMonth } ${ parts[2] }, ${ parts[3] }`;
  }


  export function parseToIOSDate(inputDate: string): string {
    const [monthString, yearString] = inputDate.split('/');

    const month: number = parseInt(monthString, 10);
    const year: number = 2000 + parseInt(yearString, 10);

    const date: Date = new Date(year, month, 1);

    // Возвращаем дату в формате YYYY-MM-DD
    return date.toISOString().slice(0, 10);
  }


  export function parseFromIOSDate(inputDate: string): string {
    const dateParts: string[] = inputDate.split('-');
    const month: string = dateParts[1];
    const year: string = dateParts[0].slice(-2);

    return month + year;
  }


  export function getQuarterWordRu(number: number): string {
    const lastDigit = number % 10;
    const lastTwoDigits = number % 100;

    if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
      return `кварталов`;
    }

    if (lastDigit === 1) {
      return `квартал`;
    }

    if (lastDigit >= 2 && lastDigit <= 4) {
      return `квартала`;
    }

    return `кварталов`;
  }

  export function getQuarterWordEn(number: number): string {
    return number === 1 ? `quarter` : `quarters`;
  }

  export function getQuarterWordBy(number: number): string {
    const lastDigit = number % 10;
    const lastTwoDigits = number % 100;

    if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
      return `кварталаў`;
    }

    if (lastDigit === 1) {
      return `квартал`;
    }

    if (lastDigit >= 2 && lastDigit <= 4) {
      return `кварталы`;
    }

    return `кварталаў`;
  }


  export function durationUnitQrt(value: number, translateService: TranslateService) {
    switch (translateService.currentLang) {
      case 'EN':
        return getQuarterWordEn(value);
      case 'RU':
        return getQuarterWordRu(value);
      case 'BE':
        return getQuarterWordBy(value);
      default:
        return '';
    }
  }

  export function getDayWordBy(number: number): string {
    const lastDigit = number % 10;
    const lastTwoDigits = number % 100;

    if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
      return `${number} дзён`;
    }

    if (lastDigit === 1) {
      return `${number} дзень`;
    }

    if (lastDigit >= 2 && lastDigit <= 4) {
      return `${number} дні`;
    }

    return `${number} дзён`;
  }

  export function getDayWordRu(number: number): string {
    const lastDigit = number % 10;
    const lastTwoDigits = number % 100;

    if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
      return `дней`;
    }

    if (lastDigit === 1) {
      return `день`;
    }

    if (lastDigit >= 2 && lastDigit <= 4) {
      return `дня`;
    }

    return `дней`;
  }

  export function getDayWordEn(number: number): string {
    return number === 1 ? `day` : `days`;
  }

  export function durationUnitDay(value: number, translateService: TranslateService) {
    switch (translateService.currentLang) {
      case 'EN':
        return getDayWordEn(value);
      case 'RU':
        return getDayWordRu(value);
      case 'BE':
        return getDayWordBy(value);
      default:
        return '';
    }
  }

  export function getYearWordRu(number: number): string {
    // Разделим число на разряды
    const lastDigit = number % 10;
    const lastTwoDigits = number % 100;

    // Проверим условия для правильного склонения слова "год"
    if (lastTwoDigits > 10 && lastTwoDigits < 20) {
      return `лет`;
    }

    if (lastDigit === 1) {
      return `год`;
    }

    if (lastDigit >= 2 && lastDigit <= 4) {
      return `года`;
    }

    return `лет`;
  }

  export function getYearWordEn(number: number): string {
    return number === 1 ? `year` : `years`;
  }

  export function getYearWordBe(number: number): string {
    const lastDigit = number % 10;
    const lastTwoDigits = number % 100;

    if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
      return `гадоў`;
    }

    if (lastDigit === 1) {
      return `год`;
    }

    if (lastDigit >= 2 && lastDigit <= 4) {
      return `гады`;
    }

    return `гадоў`;
  }

  export function durationUnitYear(value: number, translateService: TranslateService) {
    switch (translateService.currentLang) {
      case 'EN':
        return getYearWordEn(value);
      case 'RU':
        return getYearWordRu(value);
      case 'BE':
        return getYearWordBe(value);
      default:
        return '';
    }
  }


  export function monthEnding(value: number, translateService: TranslateService) {
    switch (translateService.currentLang) {
      case 'EN':
        return monthEndingEN(value);
      case 'RU':
        return monthEndingRU(value);
      case 'BE':
        return monthEndingBY(value);
      default:
        return '';
    }
  }


  export function monthEndingFor(value: number, translateService: TranslateService) {
    switch (translateService.currentLang) {
      case 'EN':
        return monthEndingEN(value);
      case 'RU':
        return monthEndingRU(value);
      case 'BY':
        return monthEndingBY(value);
      default:
        return '';
    }
  }


  export function monthEndingBY(value: number) {
    let res = '';
    const remainderTen = value % 10;
    const eleven = value === 11;
    const TwelveThirteenFourteen = value === 12 || value === 13 || value === 14;
    switch (remainderTen) {
      case 1:
        eleven ? res = 'аў' : res = '';
        break;
      case 2:
      case 3:
      case 4:
        TwelveThirteenFourteen ? res = 'аў' : res = 'а';
        break;
      case 5:
      case 6:
      case 7:
      case 8:
      case 9:
      case 0:
        res = 'аў';
        break;
    }
    return res;
  }


  export function monthEndingRU(value: number) {
    let res = '';
    const remainderTen = value % 10;
    const eleven = value === 11;
    const TwelveThirteenFourteen = value === 12 || value === 13 || value === 14;
    switch (remainderTen) {
      case 1:
        eleven ? res = 'ев' : res = '';
        break;
      case 2:
      case 3:
      case 4:
        TwelveThirteenFourteen ? res = 'ев' : res = 'а';
        break;
      case 5:
      case 6:
      case 7:
      case 8:
      case 9:
      case 0:
        res = 'ев';
        break;
    }
    return res;
  }


  export function monthEndingEN(value: number) {
    let res = '';
    const remainderTen = value % 10;
    const eleven = value === 11;
    const TwelveThirteenFourteen = value === 12 || value === 13 || value === 14;
    switch (remainderTen) {
      case 1:
        eleven ? res = 's' : res = '';
        break;
      case 2:
      case 3:
      case 4:
        TwelveThirteenFourteen ? res = 's' : res = 's';
        break;
      case 5:
      case 6:
      case 7:
      case 8:
      case 9:
      case 0:
        res = 's';
        break;
    }
    return res;
  }


  export function loanEnding(value: number, currentLang: string) {
    const multipleEnding = currentLang === 'EN' ? 's' : currentLang === 'BY' ? 'аў' : 'ов';
    const singleEnding = currentLang === 'EN' ? 's' : currentLang === 'BY' ? 'ў' : 'а';
    let res = '';
    const remainderTen = value % 10;
    const eleven = value === 11;
    const TwelveThirteenFourteen = value === 12 || value === 13 || value === 14;
    switch (remainderTen) {
      case 1:
        eleven ? res = multipleEnding : res = '';
        break;
      case 2:
      case 3:
      case 4:
        TwelveThirteenFourteen ? res = multipleEnding : res = singleEnding;
        break;
      case 5:
      case 6:
      case 7:
      case 8:
      case 9:
      case 0:
        res = multipleEnding;
        break;
    }
    return res;
  }


  export function addLeadingZero(value: number): string {
    return value < 10 ? `0${ value }` : `${ value }`;
  }


  export function formattedExpiry(inputValue: string): string {
    const month = Number(inputValue.substring(0, 2)) - 1; // Уменьшаем месяц на 1
    const year = '20' + inputValue.substring(3, 5); // Получаем год из введенной строки

    // Создаем объект Date с последним днем месяца текущего года и месяца
    const lastDayOfMonth = new Date(Number(year), month + 1, 0).getDate();

    return year + '-' + inputValue.substring(0, 2) + '-' + lastDayOfMonth.toString().padStart(2, '0');
  }


  export function addDateDays(date: Date, days: number) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }


  export function onlyDateAfterDaysTeamViro(date: string, days: number) {
    const date1 = parseTeamViroDateString(date);
    return date1 ? addDateDays(date1, days).toISOString().split('.')[0].split('T')[0].replace(/(\d{4})-(\d{2})-(\d{2})/, '$3.$2.$1') : undefined;
  }


  export function parseTeamViroDateString(dateString: string) {
    if (dateString) {
      const [datePart, timePart] = dateString.split('T');
      const [day, month, year] = datePart.split('/').map(Number);
      if (timePart) {
        const [hours, minutes, seconds] = timePart.split(':').map(Number);
        return new Date(year, month - 1, day, hours, minutes, seconds);
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }


  export function compareTeamViroDateStrings(dateString1: string, dateString2: string) {
    const date1 = parseTeamViroDateString(dateString1);
    const date2 = parseTeamViroDateString(dateString2);

    return date1 && date2 ? date1.getTime() - date2.getTime() : 0;
  }


  export function isToday(date: Date): boolean {
    const today: Date = new Date();

    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  }


  export function isYesterday(date: Date): boolean {
    const yesterday: Date = new Date();

    yesterday.setDate(yesterday.getDate() - 1);

    return (
      date.getDate() === yesterday.getDate() &&
      date.getMonth() === yesterday.getMonth() &&
      date.getFullYear() === yesterday.getFullYear()
    );
  }


  export function changeDateFormatToDot(date: string): string {
    const dateParts: string[] = date.split('-');

    if (dateParts.length === 3) {
      return dateParts[2] + '.' + dateParts[1] + '.' + dateParts[0];
    } else {
      return date;
    }
  } // input: 2015-01-22, output: 22.01.2015


  export function isTimeEqualOrGreater(currentTime: string, equalTime: string): boolean {
    const [currHours, currMinutes] = currentTime.split(':').map(Number);
    const [equalHours, equalMinutes] = equalTime.split(':').map(Number);

    const totalCurrTime: number = currHours * 60 + currMinutes;
    const totalEqualTime: number = equalHours * 60 + equalMinutes;

    return totalEqualTime >= totalCurrTime;
  }


  export function isSelectedDateToday(selectedDate: Date): boolean {
    const today: Date = new Date();

    return (
      selectedDate.getFullYear() === today.getFullYear() &&
      selectedDate.getMonth() === today.getMonth() &&
      selectedDate.getDate() === today.getDate()
    );
  }


  export function timestampToTimestampWithoutLocale(timestamp: number): number {
    const timezoneOffset = new Date().getTimezoneOffset() * 60 * 1000; // Разница в миллисекундах

    return timestamp + timezoneOffset;
  }
}
