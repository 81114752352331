import { Component, HostListener, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SharedEnum } from '../../../../../enums/shared.enum';
import { ErrorPopupInterface } from '../../../../../interfaces/popup.interface';


@Component({
  selector: 'app-error-popup',
  templateUrl: './error-popup.component.html',
  styleUrls: [ './error-popup.component.scss' ]
})
export class ErrorPopupComponent<T> {

  public icon!: string;

  public title!: string;

  public text!: string;

  public bgColor: string = '#E62E4D';

  public buttonText!: string;

  public buttonType!: SharedEnum.ButtonTypes;

  public popupStatus = SharedEnum.PopupCloseStatus;

  public dataProps!: T;


  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<ErrorPopupComponent<T>>,
    @Inject(MAT_DIALOG_DATA) public data: ErrorPopupInterface<T>) {
    this.dataProps = data.props;
    this.icon = data.icon as string;
    this.bgColor = data.bgColor ? data.bgColor : this.bgColor;
    this.title = data.title;
    this.text = data.text;
    this.buttonText = data.buttonText;
    this.buttonType = data.buttonType as SharedEnum.ButtonTypes;
  }


  @HostListener('document:click', [ '$event' ])
  onClickOutside(): void {
    if (!this.dialog.openDialogs.length) {
      this.dialogRef.close(this.dataProps);
    }
  }


  public submitPopup(): void {
    this.dialogRef.close(this.dataProps);
  }

}
