import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { DATE_FORMAT } from '../../../../../config/date.config';
import { FormControlValue } from '../../../../../interfaces/control.interface';
import { InputComponent } from '../../impl/input.component';
import { CustomDateAdapter } from './custom-date-adapter/custom-date-adapter';


@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: [ './date.component.scss' ],
  providers: [
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: MAT_DATE_LOCALE, useValue: 'ru-RU' },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT },
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DateComponent
    }
  ]
})
export class DateComponent extends InputComponent implements OnInit {

  public formControl!: FormControl<string>;

  public text: string = '';

  public tempValue!: Date;

  @Input()
  public min!: Date | undefined;

  @Input()
  public max!: Date | undefined;

  @Input()
  public placeholder!: string;

  @Input()
  public formControlCustom: FormControl<string> | undefined | AbstractControl<string>;

  @Input()
  public invalid!: boolean;

  @Input()
  public isActionsNeed!: boolean;

  @Output()
  public closed = new EventEmitter<void>();

  @Output()
  public emitDate: EventEmitter<Date> = new EventEmitter<Date>();

  @Output()
  public emitDeclineInvalid: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('inputElement') inputElement!: ElementRef;


  constructor(
    private el: ElementRef,
    private translateService: TranslateService
  ) {
    super();
  }


  override get value(): FormControlValue {
    return this._value;
  }


  @Input()
  override set value(val: FormControlValue) {
    this._value = val;

    this.onChange(this._value);
  }


  public ngOnInit(): void {
    this.initFormControl();
    this.initData();
  }


  public onClosed(): void {
    this.formControl.markAsUntouched();
    this.inputElement.nativeElement.classList.remove('focus');
    this.closed.emit();
  }


  public focusIn(): void {
    this.inputElement.nativeElement.classList.add('focus');
  }


  public focusOut(): void {
    this.inputElement.nativeElement.classList.remove('focus');
  }


  public onDateChange(event: any): void {
    const inputValue = event.target.value;

    this.inputElement.nativeElement.classList.add('focus');

    this.value = inputValue;
    this.formControl.setValue(inputValue, { emitEvent: true });

    this.emitDate.emit(inputValue);
  }


  public onDateChangeApply(event: any): void {
    const inputValue = event.target.value;

    this.inputElement.nativeElement.classList.add('focus');
    this.tempValue = inputValue;
  }


  public applyDate(): void {
    this.value = this.tempValue;

    this.formControl.setValue(this.tempValue as any, { emitEvent: true });

    this.emitDate.emit(this.tempValue);
  }


  public openDatepicker(picker: any): void {
    picker.open();

    this.invalid = false;
    this.emitDeclineInvalid.emit(false);

    if (this.formControl.touched) {
      this.inputElement.nativeElement.classList.add('focus');
    }
  }


  private initFormControl(): void {
    if (!this.formControlCustom) {
      this.formControl = new FormControl<string>('', { nonNullable: true });
    } else {
      this.formControl = this.formControlCustom as FormControl;
      this.value = this.formControl.value;
    }
  }


  private initData(): void {
    this.placeholder = this.translateService.instant('DATE_FORMAT');
  }
}
