import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';


@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: [ './checkbox.component.scss' ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CheckboxComponent
    }
  ]
})
export class CheckboxComponent implements ControlValueAccessor {

  @Input()
  public disabled!: boolean;

  @Input()
  public activeLabel!: boolean;

  @Input()
  public invalid!: boolean;


  @Input()
  set checked(val: boolean) {
    if (this._checked !== val) {
      this._checked = val;

      if (this.onChange) {
        this.onChange(this._checked);
      }

      if (this.emitChecked) {
        this.emitChecked.emit(this._checked);
      }
    }
  }


  get checked(): boolean {
    return this._checked;
  }


  @Output()
  public emitChecked: EventEmitter<boolean> = new EventEmitter();

  private _checked!: boolean;

  private onChange = (value: boolean) => {};

  private onTouched = () => {};


  constructor() {}


  public registerOnChange(fn: (value: boolean) => object): void {
    this.onChange = fn;
  }


  public registerOnTouched(fn: () => object): void {
    this.onTouched = fn;
  }


  public setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }


  public writeValue(value: boolean): void {
    this.checked = value;
  }


  public onCheck(): void {
    this.checked = !this.checked;
  }


  public onLabel(): void {
    if (this.activeLabel) {
      this.checked = !this.checked;
    }
  }
}
