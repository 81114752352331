<div class="dialog">
  <div class="dialog-header">
    <div class="dialog-header__title">{{ title | translate}}</div>
    <div class="dialog-header__close" (click)="onClose()">
      <i class="icon icon-cross"></i>
    </div>
  </div>

  <div class="dialog-payload">
    <div class="about-info-modal">
      <div class="about-info-modal-text">
        <div class="about-info-modal-text__box" *ngFor="let item of desc">{{ item | translate}}</div>
      </div>

      <div class="about-info-modal-form">
        <ng-container *ngFor="let item of consents">
          <app-checkbox
            class="about-info-modal-form__item"
            (emitChecked)="onChange($event, item)"
          >
            <ng-container *ngIf="isMultidimensionalArray(item.description)">
              <ng-container *ngFor="let desc of item.description">
                <span>{{ desc[0] + ' ' }}</span>
                <a class="active-link" [href]="desc[1]" target="_blank">{{ desc[2] + ' ' }}</a>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="!isMultidimensionalArray(item.description)">
              <span>{{ item.description[0] + ' ' }}</span>
              <a class="active-link" [href]="item.description[1]" target="_blank">{{ item.description[2] }}</a>
            </ng-container>
          </app-checkbox>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="dialog-actions">
    <app-button class="dialog-actions__item" [type]="type.PRIMARY" (click)="onNext()">{{'CONTINUE' | translate}}</app-button>
  </div>
</div>
