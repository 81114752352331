import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CardAvailableActions } from '../../../enums/card.enum';
import { ProductsEnum } from '../../../enums/products.enum';
import { IEripBodyTwoRequest } from '../../../interfaces/erip-navigation.interface';
import { HttpError } from '../../../interfaces/http.interface';
import { PaymentInterface } from '../../../interfaces/payment.interface';
import { OnlineApplication } from '../../../interfaces/products.interface';
import { PaymentResource } from '../resources/payment.resource';
import BetweenAccountsLimitsDto = PaymentInterface.BetweenAccountsLimitsDto;
import BetweenAccountsTransfer = PaymentInterface.BetweenAccountsTransfer;


@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  public productTitles = ProductsEnum.ProductTitles;

  public availableActions = CardAvailableActions;

  public payCodeEnum = PaymentInterface.PayCodeEnum;


  constructor(private readonly resource: PaymentResource) {}


  public getEripPaymentsRoc(body: IEripBodyTwoRequest, headers: HttpHeaders): Observable<PaymentInterface.TransferResponseDto> {
    return this.resource.getEripPaymentsRoc(body, headers);
  }


  public byRequisitesRoc(body: IEripBodyTwoRequest, headers: HttpHeaders): Observable<PaymentInterface.TransferResponseDto> {
    return this.resource.byRequisitesRoc(body, headers);
  }


  public depositTransferClosingToCard(requestData: PaymentInterface.TransferDepositRequestDto): Observable<PaymentInterface.TransferDepositResponseDto> {
    return this.resource.depositTransferClosingToCard(requestData);
  }


  public paymentCardNumberCurrency(requestData: PaymentInterface.TransferCardNumberCurrencyDto): Observable<PaymentInterface.TransferByCardCurrencyRs> {
    return this.resource.paymentCardNumberCurrency(requestData);
  }


  public paymentTransferInfo(requestData: PaymentInterface.TransferCardNumberCurrencyDto): Observable<PaymentInterface.TransferByCardCurrencyRs> {
    return this.resource.paymentTransferInfo(requestData);
  }


  public paymentCardNumberTransfer(requestData: PaymentInterface.TransferCardNumberTransferDto, headers: HttpHeaders): Observable<PaymentInterface.TransferDepositResponseDto> {
    return this.resource.paymentCardNumberTransfer(requestData, headers);
  }


  public depositTransferClosingToAccount(requestData: PaymentInterface.TransferDepositRequestDto): Observable<PaymentInterface.TransferDepositResponseDto> {
    return this.resource.depositTransferClosingToAccount(requestData);
  }


  public depositTransferTopUpFromCard(requestData: PaymentInterface.TransferDepositRequestDto): Observable<PaymentInterface.TransferDepositResponseDto> {
    return this.resource.depositTransferTopUpFromCard(requestData);
  }


  public depositTransferTopUpFromAccount(requestData: PaymentInterface.TransferDepositRequestDto): Observable<PaymentInterface.TransferDepositResponseDto> {
    return this.resource.depositTransferTopUpFromAccount(requestData);
  }


  public depositTransferWithdrawalFromCard(requestData: PaymentInterface.TransferDepositRequestDto): Observable<PaymentInterface.TransferDepositResponseDto> {
    return this.resource.depositTransferWithdrawalFromCard(requestData);
  }


  public depositTransferWithdrawalFromAccount(requestData: PaymentInterface.TransferDepositRequestDto): Observable<PaymentInterface.TransferDepositResponseDto> {
    return this.resource.depositTransferWithdrawalFromAccount(requestData);
  }


  public depositTransferOpening(requestData: PaymentInterface.GetPayListRequestDto, headers: HttpHeaders): Observable<PaymentInterface.CheckBaseResponseDto> {
    return this.resource.depositTransferOpening(requestData, headers);
  }


  public betweenAccountLimits(requestData: PaymentInterface.GetLimitsRequestDto): Observable<PaymentInterface.TransferLimitsResponseDto> {
    return this.resource.betweenAccountLimits(requestData);
  }


  public betweenAccountsLimits(requestData: BetweenAccountsLimitsDto): Observable<PaymentInterface.TransferLimitsResponseDto> {
    return this.resource.betweenAccountsLimits(requestData);
  }


  public transferClientLimits(): Observable<PaymentInterface.BetweenAccountsLimitsRs> {
    return this.resource.transferClientLimits();
  }


  public betweenAccountsTransfer(requestData: BetweenAccountsTransfer, headers: HttpHeaders): Observable<PaymentInterface.TransferDepositResponseDto> {
    return this.resource.betweenAccountsTransfer(requestData, headers);
  }


  public loanPaymentInfo(requestData: PaymentInterface.LoanPaymentInfoDto): Observable<PaymentInterface.CreditInfoResponse> {
    return this.resource.loanPaymentInfo(requestData);
  }


  public loanPayment(requestData: PaymentInterface.LoanPaymentInfoDto, headers: HttpHeaders): Observable<PaymentInterface.TransferDepositResponseDto> {
    return this.resource.loanPayment(requestData, headers);
  }


  public transferType(requestData: PaymentInterface.EncryptedRequestDto): Observable<PaymentInterface.TransferTypeResponseDto> {
    return this.resource.transferType(requestData);
  }


  public getRestriction(requestData: PaymentInterface.EncryptedRequestDto): Observable<PaymentInterface.RestrictionsResponseDto> {
    return this.resource.getRestriction(requestData);
  }


  public doTransaction(requestData: PaymentInterface.EncryptedRequestDto): Observable<PaymentInterface.TransferReceiptDto> {
    return this.resource.doTransaction(requestData);
  }


  public getTransactionStatus(requestData: PaymentInterface.GetTransactionStatusDto): Observable<PaymentInterface.TransferReceiptDto> {
    return this.resource.getTransactionStatus(requestData);
  }


  public getReceipt(requestData: string): Observable<PaymentInterface.ReceiptResponseDto> {
    return this.resource.getReceipt(requestData);
  }


  public getEncryptionPublicKey(): Observable<PaymentInterface.GetPublicSignatureResponseDto> {
    return this.resource.getEncryptionPublicKey();
  }


  public getAvailablePaymentActions(): Observable<PaymentInterface.AvailableActionsResponseDto> {
    return this.resource.getAvailablePaymentActions();
  }


  public paymentTopUpTransferGpl(requestData: PaymentInterface.GetPayListRequestDto): Observable<PaymentInterface.GetPayListResponseDto> {
    return this.resource.paymentTopUpTransferGpl(requestData);
  }


  public paymentTopUpTransferRoc(requestData: PaymentInterface.RunOperationRequestDto, headers: HttpHeaders): Observable<PaymentInterface.CheckBaseResponseDto> {
    return this.resource.paymentTopUpTransferRoc(requestData, headers);
  }


  public getPaymentTemplate(): Observable<PaymentInterface.ListPaymentTemplateRespDto> {
    return this.resource.getPaymentTemplate();
  }


  public createPaymentTemplate(requestData: PaymentInterface.CreatePaymentTemplateRequestDto): Observable<HttpError> {
    return this.resource.createPaymentTemplate(requestData);
  }


  public updatePaymentTemplate(requestData: PaymentInterface.UpdatePaymentTemplateDto): Observable<HttpError> {
    return this.resource.updatePaymentTemplate(requestData);
  }


  public deletePaymentTemplate(id: string): Observable<HttpError> {
    return this.resource.deletePaymentTemplate(id);
  }


  public updateAutoPayment(requestData: PaymentInterface.UpdateAutoPaymentRequestDto): Observable<HttpError> {
    return this.resource.updateAutoPayment(requestData);
  }


  public createAutoPayment(requestData: PaymentInterface.CreatePaymentTemplateRequestDto): Observable<HttpError> {
    return this.resource.createAutoPayment(requestData);
  }


  public deleteAutoPayment(id: string): Observable<HttpError> {
    return this.resource.deleteAutoPayment(id);
  }


  public getOperationHistory(requestData: PaymentInterface.GetOperationHistoryRequestDto): Observable<PaymentInterface.GetOperationHistoryResponseDto> {
    return this.resource.getOperationHistory(requestData);
  }


  public getLoanProducts(): Observable<PaymentInterface.LoanProduct[]> {
    return this.resource.getLoanProducts();
  }


  public getLoanStatus(): Observable<PaymentInterface.LoanProduct[]> {
    return this.resource.getLoanStatus();
  }


  public getApplicationCodeStatus(requestData: PaymentInterface.ApplicationCodeRq): Observable<OnlineApplication> {
    return this.resource.getApplicationCodeStatus(requestData);
  }


  public getPaymentSchedule(requestData: PaymentInterface.PaymentScheduleRq): Observable<PaymentInterface.PaymentSchedule> {
    return this.resource.getPaymentSchedule(requestData);
  }


  public fixOffer(requestData: PaymentInterface.FixOfferRq): Observable<PaymentInterface.FixOfferRs> {
    return this.resource.fixOffer(requestData);
  }


  public getOffers(requestData: PaymentInterface.ApplicationCodeRq): Observable<PaymentInterface.Offer> {
    return this.resource.getOffers(requestData);
  }


  public getLoanForm(requestData: PaymentInterface.LoanForm): Observable<PaymentInterface.LoanFormRs> {
    return this.resource.getLoanForm(requestData);
  }


  public getLoanViewDocument(requestData: PaymentInterface.LoanDocument): Observable<PaymentInterface.LoanDocumentRs> {
    return this.resource.getLoanViewDocument(requestData);
  }


  public getLoanIssue(requestData: PaymentInterface.LoanFilledForm, headers: HttpHeaders): Observable<PaymentInterface.LoanDocumentRs> {
    return this.resource.getLoanIssue(requestData, headers);
  }


  public loanVoid(requestData: PaymentInterface.LoanVoid): Observable<PaymentInterface.LoanDocumentRs> {
    return this.resource.loanVoid(requestData);
  }


  public checkSignDocument(data: PaymentInterface.CheckSignDocumentRq): Observable<PaymentInterface.CheckSignDocumentRs> {
    return this.resource.checkSignDocument(data);
  }


  public signFinalDocuments(data: PaymentInterface.SignFinalDocumentsRq): Observable<PaymentInterface.SignFinalDocumentsRs> {
    return this.resource.signFinalDocuments(data);
  }


  public getApplicationByNumber(applicationNumber: number): Observable<PaymentInterface.ApplicationResponse> {
    return this.resource.getApplicationByNumber(applicationNumber);
  }


  public reviewApplication(requestData: PaymentInterface.ReviewApplicationRq): Observable<any> {
    return this.resource.reviewApplication(requestData);
  }


  public voidApplication(requestData: PaymentInterface.VoidApplicationRq): Observable<PaymentInterface.VoidApplicationRs> {
    return this.resource.voidApplication(requestData);
  }
}
