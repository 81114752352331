export enum ConsentStatusTitle {
  AwaitingAuthorisation = 'CONSENTS.AWAITING_AUTHORISATION',
  Expired = 'CONSENTS.EXPIRED',
  Authorised = 'CONSENTS.AUTHORISED',
  Revoked = 'CONSENTS.REVOKED',
  Rejected = 'CONSENTS.REJECTED'
}


export enum DocTypeConsent {
  DBO_CONSENT_FRAUD_REPORTING = 'DBO_CONSENT_FRAUD_REPORTING'
}


export enum ConsentStatus {
  AwaitingAuthorisation = 'AwaitingAuthorisation',
  Expired = 'Expired',
  Authorised = 'Authorised',
  Revoked = 'Revoked',
  Rejected = 'Rejected',
  NotCreated = 'NotCreated'
}


export enum ConsentAction {
  Reject = 'Reject',
  Revoke = 'Revoke',
  Authorize = 'Authorize'
}


export enum AccountStatus {
  Enabled = 'Enabled',
  Disabled = 'Disabled',
  Deleted = 'Deleted',
  Pending = 'Pending'
}


export enum AccountStatusTitle {
  Enabled = 'CONSENTS.ENABLED',
  Disabled = 'CONSENTS.DISABLED',
  Deleted = 'CONSENTS.DELETED',
  Pending = 'CONSENTS.PENDING'
}
