import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { map, Observable, tap } from 'rxjs';
import { MsiDictNames, MsiErrorMessage, MsiQuestion } from '../../../enums/msi.enum';
import { Credentials } from '../../../interfaces/auth.interface';
import {
  DictionaryItem,
  MsiConfirmRequest,
  MsiForm,
  MsiLoginData,
  MsiOffer,
  MsiQuestStep,
  MsiRedirect,
  MsiStateModel,
  SoatoItem,
  UpdateMsiFormData
} from '../../../interfaces/msi.interface';
import { dictToMap } from '../../../utils/dict.utils';
import { setValueToString } from '../../../utils/msi.units';
import { deviceInfo, systemInfo } from '../../../utils/system.utils';
import { AuthState } from '../../auth/state/auth.state';
import { MsiService } from '../services/msi.service';
import {
  ClearMsiCredentials,
  GetDictionaries,
  GetMsiQuestForm,
  GetMsiRedirectUrl,
  GetMsiRedirectUrlBiometric,
  GetNsiInformation,
  MsiCode,
  MsiConfirm,
  MsiLogin,
  MsiPostForm,
  ResetMsiState,
  SaveConfirmedConsents,
  SetMsiCredentials,
  UpdateMsiForm,
  UpdateMsiState
} from './msi.actions';


@State<MsiStateModel>({
  name: 'msi'
})
@Injectable()
export class MsiState {

  constructor(
    private readonly msiService: MsiService,
    private readonly store: Store) {
  }


  @Selector()
  public static selectPhoneNumber(state: MsiStateModel): string {
    const phone = state.questionnaire.stepList[0].groupList[4].questionList.find(i => i.id === MsiQuestion.CONTACT_PHONE);
    if (!phone) {
      throw new Error(MsiErrorMessage.MISSING_CONTACT_PHONE);
    }
    return phone?.value as string;
  }


  @Selector()
  public static selectSoatoList(state: MsiStateModel): SoatoItem[] {
    return state.soatoList || [];
  }


  @Selector()
  public static selectSessionToken(state: MsiStateModel): string {
    if (!state.sessionToken) {
      throw new Error(MsiErrorMessage.SESSION_TOKEN_DEFINED);
    }
    return state.sessionToken as string;
  }


  @Selector()
  public static selectRedirectUrl(state: MsiStateModel): string {
    if (!state?.redirectUrl) {
      throw new Error(MsiErrorMessage.MSI_NOT_DEFINED);
    }
    return state?.redirectUrl as string;
  }


  @Selector()
  public static selectState(state: MsiStateModel): string {
    return state.state;
  }


  @Selector()
  public static selectOffer(state: MsiStateModel): MsiOffer {
    return state.offer;
  }


  @Selector()
  public static selectCode(state: MsiStateModel): string {
    return state.code;
  }


  @Selector()
  public static selectEventId(state: MsiStateModel): string {
    return state.eventId;
  }


  @Selector()
  public static selectMsiFlag(state: MsiStateModel): boolean {
    return state.msiFlag as boolean;
  }


  @Selector()
  public static selectDictionaries(state: MsiStateModel): Map<MsiDictNames, DictionaryItem[]> {
    return state.dictionaries;
  }


  @Selector()
  public static selectQuestSteps(state: MsiStateModel): MsiQuestStep[] {
    return state.questionnaire.stepList[0].groupList;
  }


  @Selector()
  public static selectQuestStep(state: MsiStateModel): (id: string) => MsiQuestStep {
    const groups = state.questionnaire.stepList[0].groupList;

    return (id: string): MsiQuestStep => {
      const group = groups.find(i => i.id === id);

      if (!group) {
        throw new Error(setValueToString.DynamicValue(id));
      }
      return group;
    };
  }


  @Selector()
  public static selectNextStepGroup(state: MsiStateModel): (id: string) => MsiQuestStep | null {
    const groups = state.questionnaire.stepList[0].groupList;

    return (id: string): MsiQuestStep | null => {
      const group = MsiState.selectQuestStep(state)(id);
      const currentIndex = groups.indexOf(group);

      if ((currentIndex + 1) !== groups.length) {
        return groups[currentIndex + 1];
      }
      return null;
    };
  }


  @Selector()
  public static selectConfirmedConsents(state: MsiStateModel): string[] {
    return state.confirmedConsents || [];
  }


  @Selector()
  public static selectIsNeedRegistration(state: MsiStateModel): boolean {
    return !!state.isNeedRegistration;
  }


  @Selector()
  public static selectCredentials(state: MsiStateModel): Credentials | undefined {
    return state.credentials;
  }


  @Selector()
  public static selectCodeHeader(state: MsiStateModel): string {
    return state.codeHeader || '';
  }


  @Action(GetMsiRedirectUrl)
  public getRedirectUrl(ctx: StateContext<MsiStateModel>): Observable<MsiStateModel> {
    return this.msiService.getRedirectURL(systemInfo()).pipe(
      map(({ eventId, webViewUrl }: MsiRedirect) => ctx.patchState({
        eventId,
        redirectUrl: webViewUrl
      }))
    );
  }


  @Action(GetMsiRedirectUrlBiometric)
  public getRedirectUrlMSIBiometric(ctx: StateContext<MsiStateModel>): Observable<MsiStateModel> {
    return this.msiService.getRedirectURL({ ...systemInfo(), needBiometricMSI: true }).pipe(
      map(({ eventId, webViewUrl }: MsiRedirect) => ctx.patchState({
        eventId,
        redirectUrl: webViewUrl
      }))
    );
  }


  @Action(MsiLogin)
  public login(ctx: StateContext<MsiStateModel>): Observable<MsiLoginData> {
    const { eventId, code, confirmedConsents } = ctx.getState();

    return this.msiService.login({
      eventId: eventId as string,
      code: code as string,
      confirmedConsentIds: confirmedConsents || [],
      ...systemInfo(),
      metadata: this.store.selectSnapshot(AuthState.selectMetadata)
    }).pipe(
      tap(({ isNeedRegistration, registrationToken, authentication }) =>
        ctx.patchState({
          isNeedRegistration,
          registrationToken,
          credentials: { authentication }
        }))
    );
  }


  @Action(MsiCode)
  public code(ctx: StateContext<MsiStateModel>): Observable<MsiStateModel> {
    const { registrationToken } = ctx.getState();

    return this.msiService.code({
      registrationToken: registrationToken as string,
      metadata: this.store.selectSnapshot(AuthState.selectMetadata)
    }).pipe(
      map(({ header }) => ctx.patchState({ codeHeader: header }))
    );
  }


  @Action(MsiConfirm)
  public confirm(ctx: StateContext<MsiStateModel>, { confirmationCode }: MsiConfirm): Observable<MsiStateModel> {
    const { registrationToken, credentials } = ctx.getState();

    let payload: MsiConfirmRequest = {
      registrationToken: String(registrationToken),
      deviceModel: deviceInfo(),
      confirmationCode,
      ...systemInfo()
    };

    if (credentials?.authentication) {
      payload.authentication = credentials.authentication;
    } else {
      payload = {
        ...payload,
        login: credentials?.login,
        password: credentials?.password,
        metadata: this.store.selectSnapshot(AuthState.selectMetadata)
      };
    }

    return this.msiService.confirm(payload).pipe(
      map(() => ctx.patchState({
        registrationToken: undefined,
        codeHeader: undefined
      }))
    );
  }


  @Action(UpdateMsiState)
  public updateState(ctx: StateContext<MsiStateModel>, { value }: UpdateMsiState): MsiStateModel {
    return ctx.patchState(value);
  }


  @Action(SetMsiCredentials)
  public setMsiCredentials(ctx: StateContext<MsiStateModel>, { credentials }: SetMsiCredentials): MsiStateModel {
    return ctx.patchState({ credentials });
  }


  @Action(GetMsiQuestForm)
  public getQuestForm(ctx: StateContext<MsiStateModel>): Observable<MsiForm> {
    const state = ctx.getState();

    return this.msiService.getForm({
      eventId: state.eventId,
      code: state.code,
      ...systemInfo()
    }).pipe(
      tap(({ questionnaire, offer }) => {
        ctx.patchState({
          questionnaire,
          offer
        });
      })
    );
  }


  @Action(GetDictionaries)
  public getDictionaries(ctx: StateContext<MsiStateModel>): Observable<MsiStateModel> {
    return this.msiService.getDictionaries().pipe(
      map(({ dictionaries }) => ctx.patchState({ dictionaries: dictToMap(dictionaries) }))
    );
  }


  @Action(GetNsiInformation)
  public getNsiInformation(ctx: StateContext<MsiStateModel>, { payload }: GetNsiInformation): Observable<MsiStateModel> {
    return this.msiService.getNsiInformation(payload).pipe(
      map(({ soatoList }) => ctx.patchState({ soatoList }))
    );
  }


  @Action(UpdateMsiForm)
  public updateMsiForm(ctx: StateContext<MsiStateModel>, { updateMsiFormData }: UpdateMsiForm): void {
    const { questionnaire } = ctx.getState();

    ctx.patchState({
      questionnaire: {
        ...questionnaire,
        stepList: questionnaire.stepList.map(step => ({
          ...step,
          groupList: step.groupList.map(group => ({
            ...group,
            questionList: this.mapQuestionList(group, updateMsiFormData)
          }))
        }))
      }
    });
  }


  @Action(MsiPostForm)
  public msiPostForm(ctx: StateContext<MsiStateModel>): Observable<MsiStateModel> {
    const state = ctx.getState();
    const { questionnaire } = ctx.getState();

    if (!questionnaire) {
      throw new Error(MsiErrorMessage.MSI_SATE_MESSAGE);
    }

    return this.msiService.postForm({
      eventId: state.eventId,
      code: state.code,
      ...systemInfo(),
      questionnaire
    }).pipe(
      map(({ registrationToken }) => ctx.patchState({ registrationToken }))
    );
  }


  @Action(ResetMsiState)
  public resetMsiState(ctx: StateContext<MsiStateModel>): MsiStateModel {
    return ctx.patchState({
      eventId: undefined,
      code: undefined,
      state: undefined,
      sessionToken: undefined,
      dictionaries: undefined,
      questionnaire: undefined,
      offer: undefined,
      redirectUrl: undefined,
      registration: undefined,
      nsiSoatoList: undefined,
      soatoList: undefined
    });
  }


  @Action(SaveConfirmedConsents)
  public saveConfirmedConsents(ctx: StateContext<MsiStateModel>, { confirmedConsents }: SaveConfirmedConsents): void {
    ctx.patchState({ confirmedConsents });
  }


  @Action(ClearMsiCredentials)
  public clearMsiCredentials(ctx: StateContext<MsiStateModel>): void {
    ctx.patchState({ credentials: undefined });
  }


  private mapQuestionList(group: MsiQuestStep, updateMsiFormData: UpdateMsiFormData) {
    return group.questionList.map(question => {
      switch (question.id) {
        case MsiQuestion.EMAIL: {
          return { ...question, value: updateMsiFormData.email, changed: true };
        }
        case MsiQuestion.CODEWORD: {
          return { ...question, value: updateMsiFormData.codeWord, changed: true };
        }
        case MsiQuestion.SUBJECT_BIRTH_PLACE_COUNTRY: {
          return { ...question, value: updateMsiFormData.birthPlace.country, changed: true };
        }
        case MsiQuestion.SUBJECT_BIRTH_PLACE_LOCALITY_TYPE: {
          return { ...question, value: updateMsiFormData.birthPlace.localityKind, changed: true };
        }
        case MsiQuestion.SUBJECT_BIRTH_PLACE_LOCALITY: {
          return { ...question, value: updateMsiFormData.birthPlace.localityName, changed: true };
        }
        case MsiQuestion.SUBJECT_BIRTH_PLACE_SOATO: {
          return updateMsiFormData.birthPlace.code
            ? { ...question, value: updateMsiFormData.birthPlace.code, changed: true }
            : question;
        }
        case MsiQuestion.SUBJECT_BIRTH_PLACE_DISTRICT: {
          return updateMsiFormData.birthPlace.districtName
            ? { ...question, value: updateMsiFormData.birthPlace.districtName, changed: true }
            : question;
        }
        case MsiQuestion.SUBJECT_BIRTH_PLACE_REGION: {
          return updateMsiFormData.birthPlace.regionName
            ? { ...question, value: updateMsiFormData.birthPlace.regionName, changed: true }
            : question;
        }
        case MsiQuestion.SUBJECT_ADDRESS_COUNTRY: {
          return { ...question, value: updateMsiFormData.registrationAddress.country, changed: true };
        }
        case MsiQuestion.SUBJECT_ADDRESS_LOCALITY: {
          return { ...question, value: updateMsiFormData.registrationAddress.localityName, changed: true };
        }
        case MsiQuestion.SUBJECT_ADDRESS_SOATO: {
          return updateMsiFormData.registrationAddress.code
            ? { ...question, value: updateMsiFormData.registrationAddress.code, changed: true }
            : question;
        }
        case MsiQuestion.SUBJECT_ADDRESS_STREET_TYPE: {
          return { ...question, value: updateMsiFormData.registrationAddress.streetType, changed: true };
        }
        case MsiQuestion.SUBJECT_ADDRESS_STREET: {
          return { ...question, value: updateMsiFormData.registrationAddress.streetName, changed: true };
        }
        case MsiQuestion.SUBJECT_ADDRESS_HOUSE: {
          return { ...question, value: updateMsiFormData.registrationAddress.house, changed: true };
        }
        case MsiQuestion.SUBJECT_ADDRESS_BUILDING: {
          return updateMsiFormData.registrationAddress.frame
            ? { ...question, value: updateMsiFormData.registrationAddress.frame, changed: true }
            : question;
        }
        case MsiQuestion.SUBJECT_ADDRESS_APARTMENT: {
          return updateMsiFormData.registrationAddress.apartment
            ? { ...question, value: updateMsiFormData.registrationAddress.apartment, changed: true }
            : question;
        }
        case MsiQuestion.SUBJECT_ADDRESS_POSTAL_CODE: {
          return { ...question, value: updateMsiFormData.registrationAddress.zipCode, changed: true };
        }
        case MsiQuestion.SUBJECT_ADDRESS_REGION: {
          return updateMsiFormData.registrationAddress.regionName
            ? { ...question, value: updateMsiFormData.registrationAddress.regionName, changed: true }
            : question;
        }
        case MsiQuestion.SUBJECT_ADDRESS_DISTRICT: {
          return updateMsiFormData.registrationAddress.districtName
            ? { ...question, value: updateMsiFormData.registrationAddress.districtName, changed: true }
            : question;
        }
        case MsiQuestion.SUBJECT_ADDRESS_UNITARY_AREA: {
          return updateMsiFormData.registrationAddress.unitAryArea
            ? { ...question, value: updateMsiFormData.registrationAddress.unitAryArea, changed: true }
            : question;
        }
        case MsiQuestion.SUBJECT_ADDRESS_LOCALITY_TYPE: {
          return { ...question, value: updateMsiFormData.registrationAddress.localityKind, changed: true };
        }
        case MsiQuestion.CONTACT_LIVING_ADDRESS_EQ_REGISTRATION: {
          return { ...question, value: String(updateMsiFormData.addressesEqual), changed: true };
        }
        case MsiQuestion.CONTACT_LIVING_ADDRESS_COUNTRY: {
          return { ...question, value: updateMsiFormData.residenceAddress.country, changed: true };
        }
        case MsiQuestion.CONTACT_LIVING_ADDRESS_REGION: {
          return updateMsiFormData.residenceAddress.regionName
            ? { ...question, value: updateMsiFormData.residenceAddress.regionName, changed: true }
            : question;
        }
        case MsiQuestion.CONTACT_LIVING_ADDRESS_DISTRICT: {
          return updateMsiFormData.residenceAddress.districtName
            ? { ...question, value: updateMsiFormData.residenceAddress.districtName, changed: true }
            : question;
        }
        case MsiQuestion.CONTACT_LIVING_ADDRESS_UNITARY_AREA: {
          return updateMsiFormData.residenceAddress.unitAryArea
            ? { ...question, value: updateMsiFormData.residenceAddress.unitAryArea, changed: true }
            : question;
        }
        case MsiQuestion.CONTACT_LIVING_ADDRESS_LOCALITY_TYPE: {
          return { ...question, value: updateMsiFormData.residenceAddress.localityKind, changed: true };
        }
        case MsiQuestion.CONTACT_LIVING_ADDRESS_LOCALITY: {
          return { ...question, value: updateMsiFormData.residenceAddress.localityName, changed: true };
        }
        case MsiQuestion.CONTACT_LIVING_ADDRESS_SOATO: {
          return updateMsiFormData.residenceAddress.code
            ? { ...question, value: updateMsiFormData.residenceAddress.code, changed: true }
            : question;
        }
        case MsiQuestion.CONTACT_LIVING_ADDRESS_STREET_TYPE: {
          return { ...question, value: updateMsiFormData.residenceAddress.streetType, changed: true };
        }
        case MsiQuestion.CONTACT_LIVING_ADDRESS_STREET: {
          return { ...question, value: updateMsiFormData.residenceAddress.streetName, changed: true };
        }
        case MsiQuestion.CONTACT_LIVING_ADDRESS_HOUSE: {
          return { ...question, value: updateMsiFormData.residenceAddress.house, changed: true };
        }
        case MsiQuestion.CONTACT_LIVING_ADDRESS_BUILDING: {
          return updateMsiFormData.residenceAddress.frame
            ? { ...question, value: updateMsiFormData.residenceAddress.frame, changed: true }
            : question;
        }
        case MsiQuestion.CONTACT_LIVING_ADDRESS_APARTMENT: {
          return updateMsiFormData.residenceAddress.apartment
            ? { ...question, value: updateMsiFormData.residenceAddress.apartment, changed: true }
            : question;
        }
        case MsiQuestion.CONTACT_LIVING_ADDRESS_POSTAL_CODE: {
          return { ...question, value: updateMsiFormData.residenceAddress.zipCode, changed: true };
        }

        default: {
          return question;
        }
      }
    });
  }
}
