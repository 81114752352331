import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConsentStatusTitle } from '../../../enums/consent.enum';
import {
  ApiKeyInfo,
  Consent,
  ConsentInfo,
  ConsentPayload,
  ConsentUserDataResponse
} from '../../../interfaces/consent.interface';
import { ApiResponse } from '../../../interfaces/response.interface';
import { WidgetUtils } from '../../../utils/widget.utils';
import { ConsentResource } from '../resources/consent.resource';


@Injectable({
  providedIn: 'root'
})
export class ConsentService {
  constructor(
    private readonly resource: ConsentResource) {
  }


  public getAllConsents(): Observable<Consent[]> {
    return this.resource.getAllConsents();
  }


  public getConsentInfo(payload: ConsentPayload): Observable<ConsentInfo> {
    return this.resource.getConsentInfo(payload);
  }


  public deleteConsent(payload: ConsentPayload) {
    return this.resource.deleteConsent(payload);
  }


  public getAllConsentsAndApiKey(): Observable<ApiKeyInfo> {
    return this.resource.getAllConsentsAndApiKey();
  }


  public issueLongTermConsent(headers: HttpHeaders): Observable<ApiKeyInfo> {
    return this.resource.issueLongTermConsent(headers);
  }


  public revokeLongTermConsent(headers: HttpHeaders): Observable<ApiResponse> {
    return this.resource.revokeLongTermConsent(headers);
  }


  public getUserAccounts(payload: ConsentPayload): Observable<ConsentUserDataResponse> {
    return this.resource.getUserAccounts(payload);
  }


  public getExternalRepresentation(payload: ConsentUserDataResponse): Observable<ConsentUserDataResponse> {
    return this.resource.getExternalRepresentation(payload);
  }


  public authoriseConsentOwnApplication(payload: ConsentUserDataResponse, headers: HttpHeaders): Observable<{ status: string }> {
    return this.resource.authoriseConsentOwnApplication(payload, headers);
  }


  public fillStatuses(): [ string, string ][] {
    return [
      [ WidgetUtils.ALL_SELECTED_ID, 'SELECT_ALL' ],
      [ this.getStatusKey(ConsentStatusTitle.AwaitingAuthorisation), ConsentStatusTitle.AwaitingAuthorisation ],
      [ this.getStatusKey(ConsentStatusTitle.Authorised), ConsentStatusTitle.Authorised ],
      [ this.getStatusKey(ConsentStatusTitle.Rejected), ConsentStatusTitle.Rejected ],
      [ this.getStatusKey(ConsentStatusTitle.Expired), ConsentStatusTitle.Expired ],
      [ this.getStatusKey(ConsentStatusTitle.Revoked), ConsentStatusTitle.Revoked ]
    ];
  }


  public checkConsent(consent: ConsentInfo): boolean {
    return !!(consent.permissions && consent.permissions.length && consent.status && consent.creationDateTime && consent.statusUpdateDateTime);
  }


  private getStatusKey(status: ConsentStatusTitle): string {
    return Object.keys(ConsentStatusTitle).find(key => ConsentStatusTitle[key as keyof typeof ConsentStatusTitle] === status) || '';
  }
}
