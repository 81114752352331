import { Injectable } from '@angular/core';
import {
  CodeRequest,
  CodeResponse,
  ConfirmRequest,
  ConsentRequest,
  IdentifyCardRequest,
  IdentifyPersonalRequest,
  LoginConsentResponse,
  LoginRequest,
  LoginResponse, RegistrationCheckLogin,
  TemporalCodeRq,
  TemporalConfirmRequest,
  TemporalConsentRequest
} from '../../../interfaces/auth.interface';
import { HttpError } from '../../../interfaces/http.interface';
import { AuthResource } from '../resources/auth.resource';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private readonly resource: AuthResource) {
  }


  public identify(payload: IdentifyPersonalRequest | IdentifyCardRequest) {
    return this.resource.identify(payload);
  }


  public consent(payload: ConsentRequest) {
    return this.resource.consent(payload);
  }


  public code(payload: CodeRequest) {
    return this.resource.code(payload);
  }


  public confirm(payload: ConfirmRequest) {
    return this.resource.confirm(payload);
  }


  public login(payload: LoginRequest): Observable<LoginResponse> {
    return this.resource.login(payload);
  }


  public logout(payload: {metadata: string;}) {
    return this.resource.logout(payload);
  }


  public temporalConfirm(payload: TemporalConfirmRequest): Observable<LoginResponse> {
    return this.resource.temporalConfirm(payload);
  }


  public temporalConsent(payload: TemporalConsentRequest): Observable<LoginConsentResponse> {
    return this.resource.temporalConsent(payload);
  }


  public temporalCode(payload: TemporalCodeRq): Observable<CodeResponse> {
    return this.resource.temporalCode(payload);
  }


  public registrationCheckLogin(payload: RegistrationCheckLogin): Observable<HttpError> {
    return this.resource.registrationCheckLogin(payload);
  }
}
