export namespace SharedEnum {
  export enum ButtonTypes {
    PRIMARY = 'primary',
    PRIMARY_CUSTOM = 'primary-custom',
    PRIMARY_RED = 'primary-red',
    SECONDARY = 'secondary',
    SECONDARY_RED = 'secondary-red',
    BLUR = 'blur',
    GRAY = 'gray',
    DEFAULT = 'default'
  }


  export enum TimestampToDateFormat {
    SLASH = 'slash',
    DOT = 'dot',
    SHORT = 'short',
    MSLASH = 'mslash',
    COMMON = 'common'
  }


  export enum TooltipPositions {
    BELOW = 'below',
    BELOW_RIGHT = 'below-right',
    ABOVE = 'above',
    DYNAMIC = 'dynamic',
    LEFT = 'left',
    RIGHT = 'right'
  }


  export enum TooltipTheme {
    LIGHT = 'light',
    DEFAULT = 'dark'
  }


  export enum PopupCloseStatus {
    CLOSED = 'closed',
    SUBMIT = 'submit'
  }


  export enum PopupActions {
    SAVE_AS_TEMPLATE = 'SAVE_AS_TEMPLATE',
    REPEAT_OPERATION = 'REPEAT_OPERATION',
    GET_RECEIPT = 'GET_RECEIPT'
  }


  export enum DialogCustomTitle {
    ATTENTION = 'ATTENTION',
    PERSONAL_OFFER = 'PERSONAL_OFFER',
    LOAN_INTERRUPT = 'LOAN_INTERRUPT',
    ANNUL_APPLICATION = 'ANNUL_APPLICATION',
    LOAN_INTERRUPT_LATER = 'LOAN_INTERRUPT_LATER'
  }


  export enum DialogCustomSubtitle {
    BECOME_BANK_CLIENT = 'IS_BECOME_BANK_CLIENT',
    CALL_ORDER_MASSAGE = 'CALL_ORDER_MASSAGE',
    PERSONAL_OFFER_DETAIL = 'PERSONAL_OFFER_DETAIL',
    LOAND_ACCOUNT_NOT_FOUND = 'LOAND_ACCOUNT_NOT_FOUND',
    ANNUL_APPLICATION_PROCESS = 'ANNUL_APPLICATION_PROCESS',
    MSI_LOAN_SUBTITLE = 'MSI_LOAN_SUBTITLE'
  }


  export enum DialogCustomButtonText {
    BECOME_BANK_CLIENT = 'BECOME_BANK_CLIENT',
    CONTINUE = 'CONTINUE',
    UPDATE = 'UPDATE',
    LEARN = 'FAMILIARIZE',
    ANNUL = 'ANNUL',
    EXIT = 'EXIT',
    CONFIRM = 'CONFIRM'
  }


  export enum ZIndexDialog {
    LOADER_OVER_DIALOG = "50"
  }


  export enum PersonalNumberInfo {
    PASSPORT_NUMBER = 'passportNumber',
    IDENTIFIER_NUMBER = 'identifierNumber',
    CARD_FORMATTED_NUMBER = 'cardFormattedNumber'
  }


  export enum ShortCutText {
    TWENTY = '+20',
    FIFTY = '+50',
    HUNDRED = '+100',
    FIVE_HUNDRED = '+500',
    THOUSAND = '+1000',
    FIVE_THOUSAND = '+5000'
  }


  export enum ShortCutValues {
    TWENTY = '20',
    FIFTY = '50',
    HUNDRED = '100',
    FIVE_HUNDRED = '500',
    THOUSAND = '1000',
    FIVE_THOUSAND = '5000'
  }

  export enum ButtonText {
    CONTINUE = 'CONTINUE',
    TRANSFER = 'MAKE_TRANSFER',
    PAY = 'PAY',
    SEND_APPLICATION = 'SEND_APPLICATION',
    ANNUL = 'ANNUL',
    ISSUE = 'ISSUE',
    SIGN = 'SIGN',
    REFUSE = 'REFUSE'
  }


  export enum TemplateTooltipTextIcon {
    EDIT_PAYMENT = 'EDIT_PAYMENT',
    DELETE_PAYMENT = 'DELETE_PAYMENT'
  }

  export enum ServicesText {
    SERVICES = 'SERVICES',
    WORKING_HOURS = 'WORKING_HOURS',
  }

  export enum BankTermText {
    BELINVESTBANK = 'BELINVESTBANK'
  }


  export enum LoanEnumType {
    DIFFERENTIABLE = 'Дифференцированный',
    DIFFERENTIABLE_EN = 'Differentiated',
    DIFFERENTIABLE_BE = 'Дыферэнцыраваны',
    ANNUITET = 'Аннуитет',
    ANNUITET_EN = 'Annuity',
    ANNUITET_BE = 'Ануітэт'
  }


  export enum LoanCodeType {
    CROSS = 'CROSS',
    REGULAR = 'REGULAR',
    PRE_APPROVED = 'PRE_APPROVED'
  }


  export enum NewProduct {
    ACCOUNT_CONTRACT_NUMBER = "1"
  }
}
