export const AuthConst = {
  termsOfOffer: 'https://www.paritetbank.by/private/iparitet/?iparitet=new'
};


export const getSdkData = (): Promise<string> => {
  const fingerprintSdkWindow = window as any;
  if (fingerprintSdkWindow && fingerprintSdkWindow.Fingerprint) {
    return new Promise((resolve, reject) => {
      fingerprintSdkWindow.Fingerprint.get({ debug: true }, (json: any) => {
        if (json) {
          resolve(json);
        } else {
          reject(new Error('Failed to get fingerprint data'));
        }
      });
    });
  } else
    return new Promise((_, reject) => {
      reject(new Error('Failed to get fingerprint data'));
    });
};


// оставлю на всякий случай для теста
export function loadFingerprintScript(): Promise<void> {
  return new Promise((resolve, reject) => {
    const existingScript = document.querySelector('script[src*="sdk-web.min.js"]');

    if (!existingScript) {
      const script = document.createElement('script');
      script.src = window.location.origin + '/assets/scripts/sdk-web.min.js';
      script.async = true;

      script.onload = () => {
        console.log('SDK script loaded successfully');
        resolve();
      };

      script.onerror = () => {
        reject(new Error('Failed to load SDK script'));
      };

      document.body.appendChild(script);
    } else {
      console.log('SDK script already loaded');
      resolve();
    }
  });
}
