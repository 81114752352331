import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { catchError, filter, Observable, of, switchMap, take, throwError } from 'rxjs';
import { RegistrationType } from '../enums/auth.enum';
import { BlockCustomizeTypes, MainScreenSettingList } from '../enums/settings.enum';
import { Consent } from '../interfaces/params.interface';
import { SelectCustom } from '../interfaces/products.interface';
import { BlockCustomizeUnits, IntermediateState } from '../interfaces/settings.interface';
import { GetMsiRedirectUrl, SaveConfirmedConsents } from '../models/msi/state/msi.actions';
import { MsiState } from '../models/msi/state/msi.state';
import { ParamsState } from '../models/params/state/params.state';
import { PreloaderActions } from '../models/preloader/state/preloader.actions';
import { ServicesState } from '../models/services/state/services.state';
import { SettingsUtils } from '../utils/settings.utils';
import { SnackbarService } from '../views/lib/snackbar/services/snackbar.service';
import {
  AuthInfoMessagesComponent
} from '../views/modules/auth/modals/auth-info-messages/auth-info-messages.component';


@Injectable({
  providedIn: 'root'
})
export class CommonService {

  private _loadingRates!: boolean;


  constructor(
    private readonly store: Store,
    private readonly dialog: MatDialog,
    private readonly sb: SnackbarService) {}


  public getSettingsPage(): BlockCustomizeUnits[] {
    return this.store.selectSnapshot(ServicesState.selectTotalAmount);
  }


  set loadingRates(loading: boolean) {
    this._loadingRates = loading;
  }


  get loadingRates(): boolean {
    return this._loadingRates;
  }


  public getCustomizeUser(blockCustomize: BlockCustomizeUnits[], productCard: SelectCustom[]): SelectCustom[] {
    const filterBlockCustomize: BlockCustomizeUnits[] = blockCustomize
      .filter((el: BlockCustomizeUnits): boolean => el.blockCustomizeType !== BlockCustomizeTypes.EXCHANGE_RATES);
    const customizeUtils: IntermediateState = SettingsUtils.prepareData(filterBlockCustomize);

    const blockCustomizeUnits = customizeUtils.blockCustomizeUnits
      .filter(el => el.value !== MainScreenSettingList.TOTAL_AMOUNTS && el.value !== MainScreenSettingList.EXCHANGE_RATES);

    if (productCard) {
      productCard.forEach(itemData => {
        const matchItem = blockCustomizeUnits.find((item: any) => item.value === itemData.title);

        if (matchItem) {
          itemData.sortIndex = matchItem.sortIndex;
        }
      });
    }

    return productCard;
  }


  public onGoMsi(): void {
    this.store.selectOnce(ParamsState.selectConsents).pipe(
      switchMap(consents => this.openDialog(consents, AuthInfoMessagesComponent, RegistrationType.MSI)),
      take(1),
      filter(value => !!value),
      switchMap(confirmedConsents => this.store.dispatch(new SaveConfirmedConsents(confirmedConsents))),
      switchMap(() => this.store.dispatch(new PreloaderActions.Start())),
      switchMap(() => this.store.dispatch(new GetMsiRedirectUrl)),
      switchMap(() => this.store.selectOnce(MsiState.selectRedirectUrl)),
      take(1),
      catchError((errorResponse) => {
        return throwError(errorResponse.error);
      })
    ).subscribe(redirectUrl => window.location.href = redirectUrl);
  }


  public openDialog(consents: Consent[], component: any, registrationType: string): Observable<string[]> {
    if (registrationType === RegistrationType.SIMPLE && !consents) {
      return of([]);
    }

    return this.dialog.open(component, {
      data: {
        consents: consents && consents.length ? consents : [],
        message: this.store.selectSnapshot(ParamsState.selectInfoMessage)(registrationType)
      }
    }).afterClosed();
  }


  public showSnackbar(text: string, snackBarType: string): void {
    this.sb.show(text, {
      type: snackBarType,
      duration: 5000
    });
  }
}
